import React from 'react'
import styles from './marketing.module.scss'
import Layout from '../components/layout/layout'
import { graphql } from 'gatsby'
import SEO from '../components/common/seo'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { CLOCK, LIKE, MOBILE } from '../components/icons'

const Disclaimer = ({ data }) => {
  const [benefits] = [data.datoCmsDisclaimerPage.benefit.benefits]
  benefits[0].icon = <MOBILE />
  benefits[1].icon = <CLOCK />
  benefits[2].icon = <LIKE />
  return (
    <div>
      <SEO />
      <HelmetDatoCms favicon={data.datoCmsSite.faviconMetaTags} />
      <Layout>
        <div className="row bg-primary">
          <div className="col-12">
            <div className={`${styles.content} bg-white mb-5`}>
              <div className="about">
                <hr className={styles.about_special} />
                <h2 className={styles.about_title}>{data.datoCmsDisclaimerPage.title}</h2>
              </div>
              <div className="text-left">
                <div className={styles.disclaimer}>{data.datoCmsDisclaimerPage.description}</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Disclaimer

export const query = graphql`
  query DisclaimerQuery {
    datoCmsDisclaimerPage {
      title
      description
      benefit {
        benefits {
          id
          title
          description
        }
      }
    }
    datoCmsSite {
      faviconMetaTags {
        tags
      }
    }
  }
`
